<template>
  <div id="economic-advisory-council">
    <Hero :slideContent="heroSlide" />

    <div class="council_body mt-10">
      <div class="mb-16">
        <div class="sub_headings text-2xl">Economic Advisory Council</div>
        <div class="mt-6 text-xl">
          View the profiles of our Economic Advisory Council Members
        </div>
      </div>

      <ProfileBio
        v-for="member in nieacMembers"
        :key="member.id"
        :name="member.name"
        :bio="member.profile"
        :image="member.photo"
      />
    </div>
  </div>
</template>

<script src="./economic-advisory-council.page.js"></script>
<style src="./economic-advisory-council.page.css" scoped lang="css"></style>
