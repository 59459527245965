import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "vuex";

import Hero from "@/components/hero/index.vue";
import heroBg from "../../assets/images/about-us-bg.png";
import profile from "../../assets/images/img-prof.png";
import ProfileBio from "@/components/profile-bio/index.vue";

export default defineComponent({
  name: "Economic Advisory Council",
  components: { Hero, ProfileBio },
  setup() {
    const store = useStore();
    const heroSlide = [
      {
        title: "About Us",
        image: heroBg,
      },
    ];

    onMounted(() => {
      store.dispatch("getNIEACMembers");
    });

    const nieacMembers = computed(
      () => store.state.nieacMembersModule.nieacMembers
    );

    return {
      heroSlide,
      nieacMembers,
      profile,
    };
  },
});
